.appt-book {
  #appointment-book-sections {
    .confirmation {
      .confirmation-container {
        .appt-service {
          .appt-service-information {
            width: 100%;
          }
        }
      }
    }
  }
}
